import StreetRow from './StreetRow';
import Points from './Points';

const Streets = ({gameSettings, points}) =>{
    return (
        <div>
        {gameSettings ? gameSettings.photos.map((photo, i) => (
            <StreetRow photo={photo} gameID={gameSettings.gameID} number={i+1} key={i}/>
        )): null}
        </div>
    );
};

export default Streets;