import { useState } from "react";

const { TextField, Button } = require("@mui/material");

const Home = ({joinGame}) => {
	const [error, setShowError] = useState(false);

	function checkInput() {
        setShowError(false);
		let gameID = document.getElementById("gameID").value;
        
		if (gameID == null || gameID === undefined || gameID === "") {
			setShowError(true);
		}else{
            joinGame(gameID);
        }
	}

	return (
		<div style={{ textAlign: "center" }}>
			<TextField
				autoFocus
				id="gameID"
				label="Spelcode"
				type="text"
				variant="outlined"
				error={error}
				helperText={error ? "Vul een spelcode in" : null}
			/>
			<br />
			<Button
				variant="contained"
				sx={{ marginTop: "20px" }}
				onClick={() => checkInput()}>
				Deelnemen
			</Button>
		</div>
	);
};

export default Home;
