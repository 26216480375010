import './App.css';
import { useEffect, useState } from 'react';
import { Paper, ThemeProvider, createTheme, Snackbar, Alert } from "@mui/material";
import { colors } from './constants';
import Streets from './components/Streets';
import { socket } from './socket'
import Home from './components/Home';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
})

function App() {
  const [gameSettings, setGameSettings] = useState(null);
  const [points, setPoints] = useState([0, 0, 0])
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarContent, setSnackbarContent] = useState("");

  useEffect(() => {
    document.title = "Stratenjagen";
    
    socket.on('claimUpdate', (msg) => {
      let jsonMsg = JSON.parse(msg);
      setGameSettings(jsonMsg)
    })

    socket.on('points', (msg) => {
      let jsonMsg = JSON.parse(msg);

      setPoints(jsonMsg)
    })

    socket.on('connect', () => {
      console.log("Websocket connected")
    })
  }, [])

  function showSnackbar(severity, content) {
    setSnackbarSeverity(severity);
    setSnackbarContent(content);
    setOpenSnackbar(true);
  }

  function handleSnackbarClose() {
    setOpenSnackbar(false);
  }

  function joinGame(gameID) {
    console.log("Joining game")
    socket.emit("joinGame", gameID);

    socket.on("joinGameError", (msg) => {
      showSnackbar("error", msg);
      socket.off("joinGameError");
      socket.off("gameJoined");
    })

    socket.on("gameJoined", (msg) => {
      let jsonMsg = JSON.parse(msg);

      console.log(`${jsonMsg.gameID} | ${gameID}`);
      if (jsonMsg.gameID !== gameID) {
        showSnackbar("error", "Server error, probeer opnieuw");
      } else {
        setGameSettings(jsonMsg);
      }

      socket.off("joinGameError");
      socket.off("gameJoined");
    })
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarContent}
        </Alert>
      </Snackbar>
      <Paper sx={{ height: 'fit-content', width: '100%', border: 'none', borderRadius: '0px', paddingBottom: '10px' }}>
        <div style={{ color: colors.on_primary, backgroundColor: colors.primary, height: '40px', paddingBottom: '15px', fontWeight: '700', fontSize: '40px', textAlign: 'center', border: 'none', borderRadius: '0px', marginBottom: '10px' }}>
          Stratenjagen
        </div>
        {gameSettings == null || gameSettings === undefined ? (<Home joinGame={(gameID) => joinGame(gameID)} />) : (<Streets gameSettings={gameSettings} points={points} />)}
      </Paper>
    </ThemeProvider>
  );
}

export default App;
