import { Chip, Paper } from "@mui/material";
import ModalImage from "react-modal-image";
import { SERVER_URL } from "../constants";

const StreetRow = ({
	photo,
	gameID,
	number,
}) => {
	return (
		<Paper
			sx={{
				width: "fit-content",
				height: "fit-content",
				margin: "10px auto",
				padding: "5px",
			}}
			elevation={4}>
			<table>
				<tbody>
					<tr>
						<td>{number}</td>
						<td>
							<ModalImage
								small={`${SERVER_URL}/photos/${gameID}/noNames/small/${photo.photoID}`}
								large={`${SERVER_URL}/photos/${gameID}/noNames/${photo.photoID}`}
							/>
						</td>
						<td>
							<table style={{ borderSpacing: "10px" }}>
								<tbody>
									<tr>
										<td style={{ fontSize: "14px" }}>
											Foto geclaimed:
										</td>
										<td style={{ fontSize: "14px" }}>
											Naam geclaimed:
										</td>
									</tr>
									<tr style={{ height: "20px" }}>
										<td>
											{photo.photoClaimedBy !== "" && photo.photoClaimedBy !== null ? (
												<Chip
													label={
														photo.photoClaimedBy
													}></Chip>
											) : null}
										</td>
										<td>
											{photo.nameClaimedBy !== "" && photo.nameClaimedBy !== null ? (
												<Chip
													label={
														photo.nameClaimedBy
													}></Chip>
											) : null}
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
		</Paper>
	);
};

export default StreetRow;
